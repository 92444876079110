import { ScreenQuestionItem } from '@modules/learning/types/objects';
import { EQuestionTypes } from '../definitions';

const getValidationByQuestionType = (values: ScreenQuestionItem) => {
  const errors: Record<string, any> = {};

  if (Object.values(EQuestionTypes).includes(values.type)) {
    if (!values.parameters.text) {
      errors.parameters = {};
      errors.parameters.text = true;
    }
  }

  if (values.type === EQuestionTypes.MULTIPLE_CHOICE) {
    const options = values.parameters.options.map(({ value }) => {
      return value ? false : { value: true };
    });
    if (options.find((value) => value)) {
      errors.parameters = (errors.parameters || {});
      errors.parameters.options = options;
    }
  }

  if (values.type === EQuestionTypes.SLIDER) {
    if (+values.parameters.amount_of_steps < 5) {
      errors.parameters = {};
      errors.parameters.amount_of_steps = 'common:validation_number_min';
    }
    if (+values.parameters.amount_of_steps > 10) {
      errors.parameters = {};
      errors.parameters.amount_of_steps = 'common:validation_number_max';
    }
  }

  return errors;
};

export default getValidationByQuestionType;
